import { useState, useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as ROUTES from '@app/constants/routes';

const ErrorPage = () => {
  const router = useRouter();

  const [countDownTime, setCountDownTime] = useState(15);

  useEffect(() => {
    setTimeout(() => setCountDownTime(countDownTime - 1), 1000);

    if (countDownTime === 0) {
      setCountDownTime(0);
      router.push(ROUTES.HOME_PAGE);
    }
  }, [countDownTime]);

  return (
    <section className="min-h-screen flex justify-center w-full bg-background-low-em dark:bg-background-main-dark">
      <div className="py-24 flex flex-col items-center">
        <Image src="/images/error-404.png" alt="" width={400} height={300} />
        <h1 className="h1 mt-10 text-text-color-high-em dark:text-white">Oops! No results found.</h1>
        <div className="text-text-color-mid-em dark:text-text-color-low-em
        flex flex-col items-center mt-4 text-body-lg"
        >
          <p>
            Sorry, We can&apos;t seem to find the page you are looking for.
          </p>
          <p>
            For now, you will be automatically redirected to the Homepage.
          </p>
        </div>
        <div className="mt-10 space-x-4">
          <button
            type="button"
            onClick={() => router.back()}
            className="bg-white dark:bg-background-main-dark text-text-color-mid-em dark:text-text-color-low-em
            py-2 px-4 rounded-xl border border-solid border-[#E5E7EB] dark:border-[#4B5563] shadow-sm"
          >
            Go Back
          </button>
          <span
            className="bg-white dark:bg-background-main-dark text-text-color-mid-em dark:text-text-color-low-em
            py-2 px-4 rounded-xl border border-solid border-[#E5E7EB] dark:border-[#4B5563] shadow-sm"
          >
            Redirecting you in
            {' '}
            {countDownTime}
            (s)
          </span>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
